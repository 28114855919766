import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
const phoneNumberUtil = PhoneNumberUtil.getInstance();

export enum EnumCountryCode {
  "+357" = 1,
  "+44" = 2,
  "+91" = 3,
  "+376" = 5,
  "+971" = 6,
  "+93" = 7,

  // "+1-268" = 8,
  "+1-AG" = 8,
  
  //"+1-264" = 9,
  "+1-AI" = 9,

  "+355" = 10,
  "+374" = 11,

  // "+599-3" = 12,
  // "+599-4" = 12,
  // "+599-7" = 12,
  "+599-BQ" = 12,

  "+244" = 13,
  "+54" = 15,
  
  // "+1-684" = 16,
  "+1-AS" = 16,
  
  "+43" = 17,
  "+61" = 18,
  "+297" = 19,
  "+994" = 20,
  "+387" = 21,
  
  // "+1-246" = 22,
  "+1-BB" = 22,
  
  "+880" = 23,
  "+32" = 24,
  "+226" = 25,
  "+359" = 26,
  "+973" = 27,
  "+257" = 28,
  "+229" = 29,
  
  // "+1-441" = 30,
  "+1-BM" = 30,
  
  "+673" = 31,
  "+591" = 32,
  "+55" = 33,

  // "+1-242" = 34,
  "+1-BS" = 34,
  
  "+975" = 35,
  "+267" = 37,
  "+375" = 38,
  "+501" = 39,

  // "+1-204" = 40,
  // "+1-226" = 40,
  // "+1-236" = 40,
  // "+1-249" = 40,
  // "+1-250" = 40,
  // "+1-289" = 40,
  // "+1-306" = 40,
  // "+1-343" = 40,
  // "+1-365" = 40,
  // "+1-387" = 40,
  // "+1-403" = 40,
  // "+1-416" = 40,
  // "+1-418" = 40,
  // "+1-431" = 40,
  // "+1-437" = 40,
  // "+1-438" = 40,
  // "+1-450" = 40,
  // "+1-506" = 40,
  // "+1-514" = 40,
  // "+1-519" = 40,
  // "+1-548" = 40,
  // "+1-579" = 40,
  // "+1-581" = 40,
  // "+1-587" = 40,
  // "+1-604" = 40,
  // "+1-613" = 40,
  // "+1-639" = 40,
  // "+1-647" = 40,
  // "+1-672" = 40,
  // "+1-705" = 40,
  // "+1-709" = 40,
  // "+1-742" = 40,
  // "+1-778" = 40,
  // "+1-780" = 40,
  // "+1-782" = 40,
  // "+1-807" = 40,
  // "+1-819" = 40,
  // "+1-825" = 40,
  // "+1-867" = 40,
  // "+1-873" = 40,
  // "+1-902" = 40,
  // "+1-905" = 40,

  "+1-CA" = 40,

  // "+61-89162" = 41,
  "+61-CC" = 41,

  "+236" = 42,
  "+243" = 43,
  "+41" = 44,
  "+225" = 45,
  "+682" = 46,
  "+56" = 47,
  "+237" = 48,
  "+86" = 49,
  "+57" = 50,
  "+506" = 51,
  "+53" = 52,
  "+238" = 53,

  // "+61-89164" = 54,
  "+61-CX" = 54,

  "+420" = 55,
  "+49" = 56,
  "+253" = 57,
  "+45" = 58,
  
  //"+1-767" = 59,
  "+1-DM" = 59,

  // "+1-809" = 60,
  // "+1-829" = 60,
  // "+1-849" = 60,

  "+1-DO" = 60,

  "+213" = 61,
  "+593" = 62,
  "+372" = 63,
  "+20" = 64,

  // "+212-5288" = 65,
  // "+212-5289" = 65,

  "+212-EH" = 65,

  "+291" = 66,
  "+34" = 67,
  "+251" = 68,
  "+358" = 69,
  "+679" = 70,
  "+500" = 71,
  "+691" = 72,
  "+298" = 73,
  "+33" = 74,
  "+241" = 75,
  
  // "+1-473" = 76,
  "+1-GD" = 76,
  
  "+995" = 77,
  "+594" = 78,
  "+233" = 79,
  "+350" = 80,
  "+299" = 81,
  "+220" = 82,
  "+224" = 83,
  "+590" = 84,
  "+240" = 85,
  "+30" = 86,
  "+502" = 88,
  
  // "+1-671" = 89,
  "+1-GU" = 89,
  
  "+245" = 90,
  "+592" = 91,
  "+852" = 92,
  "+504" = 94,
  "+385" = 95,
  "+509" = 96,
  "+36" = 97,
  "+62" = 98,
  "+353" = 99,
  "+972" = 100,
  "+246" = 101,
  "+964" = 102,
  "+98" = 103,
  "+354" = 104,
  "+39" = 105,

  // "+1-876" = 106,
  // "+1-658" = 106,

  "+1-JM" = 106,

  "+962" = 107,
  "+81" = 108,
  "+254" = 109,
  "+996" = 110,
  "+855" = 111,
  "+686" = 112,
  "+269" = 113,

  // "+1-869" = 114,
  "+1-KN" = 114,

  "+850" = 115,
  "+82" = 116,
  "+965" = 117,
  
  // "+1-345" = 118,
  "+1-KY" = 118,

  // "+7-33" = 119,
  // "+7-7" = 119,

  "+7-KZ" = 119,

  "+856" = 120,
  "+961" = 121,
  
  // "+1-758" = 122,
  "+1-LC" = 122,

  "+423" = 123,
  "+94" = 124,
  "+231" = 125,
  "+266" = 126,
  "+370" = 127,
  "+352" = 128,
  "+371" = 129,
  "+218" = 130,
  "+212" = 131,
  "+377" = 132,
  "+133" = 133,
  "+373" = 134,
  "+692" = 135,
  "+389" = 136,
  "+223" = 137,
  "+95" = 138,
  "+976" = 139,
  "+853" = 140,

  // "+1-670" = 141,
  "+1-TL" = 141,

  "+596" = 142,
  "+222" = 143,

  // "+1-664" = 144,
  "+1-MS" = 144,

  "+356" = 145,
  "+230" = 146,
  "+960" = 147,
  "+265" = 148,
  "+52" = 149,
  "+60" = 150,
  "+258" = 151,
  "+264" = 152,
  "+687" = 153,
  "+227" = 154,
  "+672" = 155,
  "+234" = 156,
  "+505" = 157,
  "+31" = 158,
  "+47" = 159,
  "+977" = 160,
  "+674" = 161,
  "+683" = 162,
  "+64" = 163,
  "+968" = 164,
  "+507" = 165,
  "+51" = 166,
  "+689" = 167,
  "+675" = 168,
  "+63" = 169,
  "+92" = 170,
  "+48" = 171,
  "+508" = 172,

  // "+1-787" = 174,
  // "+1-939" = 174,

  "+1-PR" = 174,

  "+351" = 175,
  "+680" = 176,
  "+595" = 177,
  "+974" = 178,
  "+262" = 179,
  "+40" = 180,
  "+7" = 181,
  "+250" = 182,
  "+966" = 183,
  "+677" = 184,
  "+248" = 185,
  "+249" = 186,
  "+46" = 187,
  "+65" = 188,
  "+290" = 189,
  "+386" = 190,
  
  // "+47-79" = 191,
  "+47-SJ" = 191,

  "+421" = 192,
  "+232" = 193,
  "+378" = 194,
  "+221" = 195,
  "+252" = 196,
  "+597" = 197,
  "+239" = 198,
  "+503" = 199,
  "+963" = 200,
  "+268" = 201,
  
  // "+1-649" = 202,
  "+1-TC" = 202,

  "+235" = 203,
  "+228" = 205,
  "+66" = 206,
  "+992" = 207,
  "+690" = 208,
  "+993" = 209,
  "+216" = 210,
  "+676" = 211,
  "+670" = 212,
  "+90" = 213,

  // "+1-868" = 214,
  "+1-TT" = 214,
  
  "+688" = 215,
  "+886" = 216,
  "+255" = 217,
  "+380" = 218,
  "+256" = 219,
  "+1" = 221,
  "+598" = 222,
  "+998" = 223,

  // "+39-06698" = 224,
  "+39-VA" = 224,
  
  // "+1-784" = 225,
  "+1-VC" = 225,

  "+58" = 226,
  
  // "+1-284" = 227,
  "+1-VG" = 227,

  // "+1-340" = 228,
  "+1-VI" = 228,

  "+84" = 229,
  "+678" = 230,
  "+681" = 231,
  "+685" = 232,
  "+967" = 233,

  // "+262-269" = 234,
  // "+262-639" = 234,

  "+262-YT" = 234,

  "+27" = 236,
  "+260" = 237,
  "+263" = 239,
  "+381" = 240,
  "+247" = 241,
  
  // "+590-2" = 242,
  "+590-MF" = 242,
  
  "+242" = 243,
  
  // "+590-1" = 244,
  "+590-BL" = 244,

  // "+44-1481" = 245,
  // "+44-7781" = 245,
  // "+44-7839" = 245,
  // "+44-7911" = 245,

  "+44-GG" = 245,

  // "+44-1624" = 246,
  // "+44-74576" = 246,
  // "+44-7524" = 246,
  // "+44-7924" = 246,
  // "+44-7624" = 246,

  "+44-IM" = 246,

  // "+44-1534" = 247,
  // "+44-7509" = 247,
  // "+44-7700" = 247,
  // "+44-7797" = 247,
  // "+44-7829" = 247,
  // "+44-7937" = 247,

  "+44-JE" = 247,

  "+383" = 248,
  "+599" = 249,
  "+382" = 250,
  "+970" = 251,
  
  // "+1-721" = 252,
  "+1-SX" = 252,

  "+211" = 253,

  // "+358-18" = 254
  "+358-AX" = 254

}

export function getPhoneCountryId(phone : any): number {

  var countryId = EnumCountryCode[phone.dialCode + '-' + phone.countryCode]
  if (countryId === 0 || countryId === null || countryId === undefined) {
    countryId = EnumCountryCode[phone.dialCode];
  }
  return countryId;
}

export function getPhoneCountryCode(countryId: number, phoneNumber: string): any {
  
  if (!countryId) {
    countryId = 2;
  }

  if (!phoneNumber) {
    phoneNumber = "";
  }
  var countryCode1 =   EnumCountryCode[countryId];
  var index = countryCode1.indexOf('-');
  if(index > -1){
    countryCode1 = countryCode1.substring(0,index);
  }
  const number = phoneNumberUtil.parseAndKeepRawInput(countryCode1 + phoneNumber);
  const countryCode = phoneNumberUtil.getRegionCodeForNumber(number);

  var phone = {
    "number": phoneNumber,
    "countryCode": countryCode,
  }

  return phone;
}
